<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Gestion des produits
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="getProduitIsLoding"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getProduitError">
              <ul v-if="Array.isArray(getProduitError)" class="mb-0">
                <li v-for="(e, index) in getProduitError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getProduitError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              hiddenContent="true"
            />
          </div>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-if="checkPermission('GPTHAP')"
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click="openModalAjouterProduit"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter un produit</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="table-gestion-content">
      <v-data-table
        v-model="selectedTable"
        :headers="computedFields"
        :items="getProduits"
        :items-per-page="perPage"
        class="table-setting"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          getProduitIsLoding
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.nom`]="{ item }">
          <td class="custom-cell">
            {{ item.nom }}
          </td>
        </template>
        <template v-slot:[`item.reference`]="{ item }">
          <td class="custom-cell">
            {{ item.reference ? item.reference : '-' }}
          </td>
        </template>
        <template v-slot:[`item.pu_ht`]="{ item }">
          <td class="custom-cell">
            {{ item.pu_ht ? item.pu_ht : '0' }}
          </td>
        </template>
        <template v-slot:[`item.tva`]="{ item }">
          <td class="custom-cell">
            {{ item.tva ? item.tva : '0' }}
          </td>
        </template>
        <template v-slot:[`item.devise`]="{ item }">
          <td class="custom-cell">
            {{ item.devise ? item.devise : '-' }}
          </td>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <td class="custom-cell">
            {{ item.description ? item.description : '-' }}
          </td>
        </template>
        <template v-slot:[`item.category`]="{ item }">
          <td class="custom-cell">
            {{
              item.category && item.category.nom_categorie
                ? item.category.nom_categorie
                : '-'
            }}
          </td>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell sticky-header-end">
            <div class="d-flex actions-style-table">
              <div
                v-if="checkPermission('GPTHMP')"
                class="bloc-icon-gestion mr-2"
                @click.prevent.stop="updateProduitValue(item)"
                title="Modifier"
              >
                <font-awesome-icon icon="pencil-alt" />
              </div>
              <div
                v-if="checkPermission('GPTHSP')"
                class="bloc-icon-gestion"
                @click.prevent.stop="deleteProduit(item)"
                title="Supprimer"
              >
                <font-awesome-icon icon="trash" />
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
      <div class="footer-style-table">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            :total-visible="7"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            v-model="perPage"
            :items="perPageList"
            @change="changePerPage"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
      </div>
    </div>
    <!--MODAL ADD PRODUIT-->
    <v-dialog
      v-model="addProduit"
      max-width="1300px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-rapport"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter un produit</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addProduit')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addProduit" lazy-validation class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  label="Nom de produit"
                  v-model="produit.nom"
                  outlined
                  required
                  dense
                  class="msg-error"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Nom de produit est obligatoire']"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Référence"
                  v-model="produit.reference"
                  outlined
                  required
                  dense
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Référence est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="produit.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="getCategories"
                  v-model="produit.categorie_id"
                  label="Catégorie"
                  dense
                  outlined
                  :persistent-placeholder="true"
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  item-text="nom_categorie"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="PU HT"
                  outlined
                  dense
                  v-model="produit.pu_ht"
                  required
                  @input="changePuHT"
                  pattern="[0-9-,-.]+"
                  type="number"
                  title="This input should be only numbers"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'PU HT est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col v-if="!isSelectInput">
                <v-select
                  :items="listTva"
                  v-model="produit.tva"
                  required
                  @input="changePuHT(true)"
                  label="TVA"
                  dense
                  :rules="[v => !!v || 'TVA est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  outlined
                  :persistent-placeholder="true"
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col v-if="isSelectInput">
                <v-text-field
                  label="TVA"
                  outlined
                  dense
                  v-model="produit.tva"
                  required
                  @input="changePuHT"
                  pattern="[0-9-,-.]+"
                  type="number"
                  title="This input should be only numbers"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'TVA est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="PU TTC"
                  outlined
                  dense
                  :persistent-placeholder="true"
                  v-model="produit.ttc"
                  @input="changePuTTC"
                  pattern="[0-9-,-.]+"
                  type="number"
                  title="This input should be only numbers"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  :items="listDevise"
                  v-model="produit.devise"
                  label="Devise"
                  dense
                  outlined
                  :persistent-placeholder="true"
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Description"
                  row="2"
                  v-model="produit.description"
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul
                v-if="Array.isArray(error)"
                class="mb-0"
                style="list-style-type: none"
              >
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleSubmitAddProduit"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addProduit')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL UPDATE PRODUIT-->
    <v-dialog
      v-model="updateProduit"
      max-width="1300px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-rapport"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier un produit</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('updateProduit')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="updateProduit" lazy-validation class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  label="Nom de produit"
                  v-model="produit.nom"
                  outlined
                  required
                  dense
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Nom de produit est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Référence"
                  v-model="produit.reference"
                  outlined
                  required
                  dense
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Référence est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="produit.type"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="getCategories"
                  v-model="produit.categorie_id"
                  label="Catégorie"
                  dense
                  outlined
                  :persistent-placeholder="true"
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                  item-text="nom_categorie"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="PU HT"
                  outlined
                  dense
                  v-model="produit.pu_ht"
                  required
                  @input="changePuHT"
                  pattern="[0-9-,-.]+"
                  type="number"
                  title="This input should be only numbers"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'PU HT est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col v-if="!isSelectInput">
                <v-select
                  :items="listTva"
                  v-model="produit.tva"
                  required
                  @input="changePuHT(true)"
                  label="TVA"
                  dense
                  :rules="[v => !!v || 'TVA est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  outlined
                  :persistent-placeholder="true"
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col v-if="isSelectInput">
                <v-text-field
                  label="TVA"
                  outlined
                  dense
                  v-model="produit.tva"
                  required
                  @input="changePuHT"
                  pattern="[0-9-,-.]+"
                  type="number"
                  title="This input should be only numbers"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'TVA est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="PU TTC"
                  outlined
                  dense
                  :persistent-placeholder="true"
                  v-model="produit.ttc"
                  @input="changePuTTC"
                  pattern="[0-9-,-.]+"
                  type="number"
                  title="This input should be only numbers"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  :items="listDevise"
                  v-model="produit.devise"
                  label="Devise"
                  dense
                  outlined
                  :persistent-placeholder="true"
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Description"
                  row="2"
                  v-model="produit.description"
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul
                v-if="Array.isArray(error)"
                class="mb-0"
                style="list-style-type: none"
              >
                <li v-for="(e, index) in error" :key="index">{{ e }}</li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleUpdateProduit"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('updateProduit')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE PRODUIT-->
    <v-dialog
      v-model="deleteProduitModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer un produit</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deleteProduitModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sur de vouloir supprimer cette produit
              <span class="bold-text">{{
                produitToDelete ? produitToDelete.nom : ''
              }}</span>
              ?
            </p>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleDeleteProduit"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('deleteProduitModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Gestion-des-produits',
  data() {
    return {
      searchValue: null,
      listTva: ['0', '2.1', '5.5', '7.7', '8.5', '10', '20', 'Autre'],
      typeProduct: [
        { value: 'prestation', text: 'Prestation' },
        { value: 'marchandise', text: 'Marchandise' }
      ],
      listDevise: [
        'USD',
        'EUR',
        'GBP',
        'CAD',
        'JYP',
        'CHF',
        'XPF',
        'XAF',
        'XOF',
        'PLN'
      ],
      produitToDelete: null,
      loading: false,
      error: [],
      isSelectInput: false,
      produit: {
        nom: null,
        reference: null,
        tva: 0,
        pu_ht: 0,
        devise: 'EUR',
        description: null,
        categorie_id: null,
        ttc: 0,
        categorie_id: null,
        type: 'prestation'
      },
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      addProduit: false,
      selectedTable: [],
      deleteProduitModal: false,
      updateProduit: false
    };
  },
  methods: {
    ...mapActions([
      'fetchAllProducts',
      'addNewProduct',
      'UpdateProduct',
      'deleteProduct',
      'fetchAllCategories'
    ]),
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    openModalAjouterProduit() {
      this.produit = {
        nom: null,
        reference: null,
        tva: '0',
        pu_ht: '0',
        devise: 'EUR',
        description: null,
        categorie_id: null,
        ttc: 0,
        categorie_id: null,
        type: 'prestation'
      };
      this.addProduit = true;
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    async handleFilter() {
      await this.fetchAllProducts({
        per_page: this.perPage,
        page: this.page,
        search: this.searchValue
      });
    },
    hideModal(ref) {
      this[ref] = false;
      this.resetModal();
      if (ref == 'updateProduit') {
        this.$refs.updateProduit.reset();
      }
      if (ref == 'addProduit') {
        this.$refs.addProduit.reset();
      }
    },

    async handleSubmitAddProduit() {
      this.error = null;
      if (this.$refs.addProduit.validate()) {
        this.loading = true;
        const response = await this.addNewProduct(this.produit);
        if (response.success) {
          this.loading = false;
          this.hideModal('addProduit');
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },

    updateProduitValue(data) {
      if (this.listTva.indexOf(data.tva) === -1) {
        this.listTva.unshift(data.tva);
      }
      this.produit = { ...data };
      this.produitOrigin = data;
      this.updateProduit = true;
    },
    async handleUpdateProduit() {
      this.error = null;
      if (this.$refs.updateProduit.validate()) {
        this.loading = true;
        const response = await this.UpdateProduct({
          produit: this.produit,
          produitOrigin: this.produitOrigin
        });
        if (response.success) {
          this.loading = false;
          this.hideModal('updateProduit');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      }
    },
    deleteProduit(data) {
      this.produitToDelete = data;
      this.deleteProduitModal = true;
    },
    async handleDeleteProduit() {
      this.loading = true;
      const response = await this.deleteProduct(this.produitToDelete);
      if (response.success) {
        this.loading = false;
        this.hideModal('deleteProduitModal');
        if (this.getProduits.length === 0 && this.page > 1) {
          let newPage = this.page - 1;
          this.pagination(newPage);
        }
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    resetModal() {
      this.listTva = ['0', '2.1', '5.5', '7.7', '8.5', '10', '20', 'Autre'];
      this.produit = {
        nom: null,
        reference: null,
        tva: 0,
        pu_ht: 0,
        devise: 'EUR',
        description: null,
        categorie_id: null,
        ttc: 0,
        categorie_id: null,
        type: 'prestation'
      };
      this.isSelectInput = false;
      this.error = [];
      this.loading = false;
      this.produitToDelete = null;
    },

    changePuHT(e) {
      if (e && this.produit.tva === 'Autre' && this.isSelectInput === false) {
        this.produit.tva = 0;
        this.isSelectInput = true;
      }
      this.produit.ttc = (
        parseFloat((this.produit.pu_ht + '').replace(',', '.')) +
        (parseFloat((this.produit.pu_ht + '').replace(',', '.')) / 100) *
          parseFloat((this.produit.tva + '').replace(',', '.'))
      ).toFixed(2);
    },
    changePuTTC() {
      this.produit.pu_ht = (
        (parseFloat((this.produit.ttc + '').replace(',', '.')) /
          (100 + parseFloat((this.produit.tva + '').replace(',', '.')))) *
        100
      ).toFixed(2);
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  computed: {
    ...mapGetters([
      'getProduits',
      'getProduitError',
      'getTotalRowProduit',
      'getCategories',
      'getProduitIsLoding',
      'checkPermission'
    ]),
    totalPages() {
      if (this.getTotalRowProduit) {
        return Math.ceil(this.getTotalRowProduit / this.perPage);
      }
      return this.getTotalRowProduit;
    },
    computedFields() {
      return [
        {
          value: 'nom',
          text: 'Nom'
        },
        {
          value: 'reference',
          text: 'Réference'
        },
        {
          value: 'pu_ht',
          text: 'PU HT'
        },
        {
          value: 'tva',
          text: 'TVA'
        },
        {
          value: 'ttc',
          text: 'PU TTC'
        },
        {
          value: 'devise',
          text: 'Devise'
        },
        {
          value: 'description',
          text: 'Description'
        },
        {
          value: 'category',
          text: 'Nom catégorie'
        },
        {
          value: 'Actions',
          text: '',
          width: '100px !important',
          class: 'sticky-header-end'
        }
      ];
    }
  },
  async mounted() {
    this.handleFilter();
    await this.fetchAllCategories();
  }
};
</script>
<style scoped>
.table-gestion-content {
  max-height: calc(100vh - 253px) !important;
  height: calc(100vh - 253px) !important;
}
</style>
